import { useEffect, useState } from 'react';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import { getRedirectPathWithQuery } from '@activebrands/core-web/utils/query';
import { useLocation } from '@reach/router';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const CountrySelectWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',

    [media.min['mobile.lg']]: {
        padding: '24px',
    },

    [media.min['desktop.sm']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: '8px',
    },
});

const CountryContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '24px',

    [media.min['desktop.sm']]: {
        display: 'grid',
        gridColumn: '1 / span 4',
    },
});

const SiteSelectorInfo = styled('div', {
    ...textStyles['Secondary/16_130_400'],
});

const CountryButtonsWrapper = styled('ul', {
    display: 'flex',
    flexDirection: 'column',

    [media.min['tablet.lg']]: {
        gridColumn: '1 / span 4',
    },
});

const SectionWrapper = styled('div', {
    width: '100%',
    position: 'relative',
    zIndex: '2',
    paddingBottom: '64px',
});

const siteSelector = () => {
    const [css] = useStyletron();

    const {
        desktopBackground,
        mobileBackground,
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorStaticPageHeading,
        siteSelectorStaticPageHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
    } = useSiteSelectorQuery();

    const transformedBackground = transformFlexibleBackgrounds([mobileBackground, null, desktopBackground], 1);
    const backgroundOverlays = extractBackgroundOverlays([[mobileBackground, null, desktopBackground]]);

    const location = useLocation();
    const [pathWithQuery, setPathWithQuery] = useState('/');
    useEffect(() => {
        setPathWithQuery(getRedirectPathWithQuery(location));
    }, [location]);
    return (
        <>
            <BackgroundDynamic
                background={transformedBackground}
                backgroundOverlays={backgroundOverlays}
                loading="eager"
            />
            <div>
                {siteSelectorLinks?.length > 0 ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorStaticPageHeading || siteSelectorParagraph) && (
                                <CountryContent>
                                    {siteSelectorStaticPageHeading && (
                                        <Heading as={siteSelectorStaticPageHeadingType} fontKeys="Primary/32_-04">
                                            {siteSelectorStaticPageHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorParagraph && (
                                        <SiteSelectorInfo>
                                            <RichText data={siteSelectorParagraph} />
                                        </SiteSelectorInfo>
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorLinks.map(site => (
                                    <ThemeButton
                                        $style={{
                                            color: 'var(--color-text-inverted)',
                                            borderBottom: '1px solid var(--color-border-button-inverted)',
                                            padding: '12px 0',
                                        }}
                                        theme="link"
                                        key={site.name}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        onClick={() => setSiteSelectorCookie(site.path)}
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        marginRight: '8px',
                                                        width: '24px',
                                                        height: '16px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                className={css({ textAlign: 'left', width: '100%' })}
                                                fontKeys="Miscellaneous/16_100_-1"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
                {siteSelectorExternalLinks?.length ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorExternalHeading || siteSelectorExternalParagraph) && (
                                <CountryContent>
                                    {siteSelectorExternalHeading && (
                                        <Heading as={siteSelectorExternalHeadingType} fontKeys="Primary/32_-04">
                                            {siteSelectorExternalHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorExternalParagraph && (
                                        <SiteSelectorInfo>
                                            <RichText data={siteSelectorExternalParagraph} />
                                        </SiteSelectorInfo>
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorExternalLinks.map(site => (
                                    <ThemeButton
                                        $style={{
                                            color: 'var(--color-text-inverted)',
                                            borderBottom: '1px solid var(--color-border-button-inverted)',
                                            padding: '12px 0',
                                        }}
                                        theme="link"
                                        key={site.name}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        onClick={() => setSiteSelectorCookie(site.path)}
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        marginRight: '8px',
                                                        width: '24px',
                                                        height: '16px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                className={css({ textAlign: 'left', width: '100%' })}
                                                fontKeys="Miscellaneous/16_100_-1"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
            </div>
        </>
    );
};

export default siteSelector;
